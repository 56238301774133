<template>
  <div>
    <v-file-input
      counter
      :multiple="multiple"
      show-size
      small-chips
      truncate-length="22"
      @change="onChangeFileInput"
      :value="innerfile"
      :label="label"
      :outlined="outlined"
      :dense="dense"
      :prepend-icon="prependIcon"
    />

    <!-- <p>
      <v-row>
        <v-chip
          v-for="(item, index) in files"
          :key="index"
          close
          close-icon="mdi-close-outline"
          @click:close="files = files.filter(n => n !== item)"
          >{{ item.name }}</v-chip
        >
      </v-row>
    </p> -->
  </div>
</template>

<script>
export default {
  name: 'FileInput2',
  props: {
    params: {},
    prependIcon: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      innerfile: [],
      files: [],
      label: '',
      multiple: true
    };
  },
  mounted() {
    this.label = this.params.label;
  },
  methods: {
    fileClear() {
      this.onChangeFileInput([]);
    },
    onChangeFileInput(selectedFile) {
      if (selectedFile.length == 0) {
        this.files = [];
      }
      this.files = this.files.concat(selectedFile);
      this.files = Array.from(
        new Map(this.files.map(file => [this.getKey(file), file])).values()
      );

      this.innerfile = this.files.concat([]);
    },
    getKey(file) {
      return (
        file.lastModified + ':' + file.name + ':' + file.size + ':' + file.type
        // file.name
      );
    }
  }
};
</script>