<template>
  <v-sheet class="frame" v-resize="onResize">
    <v-toolbar class="frame" flat>
      <v-btn :to="path" icon small>
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <div>設定管理</div>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row class="fill-height" ref="card">
      <v-col cols="12" :md="disp_cols">
        <v-select
          prepend-icon="mdi-bookmark"
          label="カテゴリ"
          item-text="disp_name"
          item-value="id"
          :items="getDispCategories()"
          v-model="category_id"
          style="max-width: 300px"
          v-show="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
          @change="dispDirectiesChange"
        />
        <v-list
          v-show="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
          dense
        >
          <v-list-item-group v-model="category_id" mandatory color="indigo">
            <v-list-item
              v-for="(item, index) in this.categories"
              :key="index"
              :value="item.id"
              link
              @click="dispDirectiesChange"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.disp_name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="12" :md="data_cols">
        <template v-if="this.categories.find(x => x.id == category_id)">
          <component
            :is="this.categories.find(x => x.id == category_id).page"
          />
        </template>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import fileOperation from '../../../utils/fileOperation';
import token from '../../../utils/token';
import UserSettings from './UserSettings.vue';
import LibrarySettings from './LibrarySettings.vue';
import WorkflowSettings from './WorkflowSettings.vue';
import UserGroupSettings from './UserGroupSettings.vue';
import FacilitySettings from './FacilitySettings.vue';
import FacilityGroupSettings from './FacilityGroupSettings.vue';
import InformationSettings from './InformationSettings.vue';
import PageSettings from './PageSettings.vue';
import TagSettings from './TagSettings.vue';

export default {
  components: {
    UserSettings,
    LibrarySettings,
    WorkflowSettings,
    UserGroupSettings,
    FacilitySettings,
    FacilityGroupSettings,
    InformationSettings,
    PageSettings,
    TagSettings
  },
  data: () => ({
    name: 'adminsettings',
    path: '/admin/settings',
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    disp_cols: 2,
    data_cols: 10,
    isError: {},
    categories: [],
    category_id: 0
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading
    })
  },
  mixins: [fileOperation, token],
  methods: {
    ...mapActions([
      'fetchUserList',
      'fetchUserGroup',

      'fetchUserConfig',
      'insertUserConfig',
      'updateUserConfig'
    ]),
    init() {
      this.categories = this.getDispCategories();
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.iconSize = window.innerHeight * 0.1;
      this.calendarHeight = Math.max(this.windowSize.y - 140, 600);
      this.dialogLeft = Math.max((this.windowSize.x - 600) / 2, 0);
      let temp = this.$refs.card;
      this.cardSize = { x: temp.clientWidth, y: temp.clientHeight };

      this.selectedFullScreen = window.innerWidth < 600;
      this.editedFullScreen = window.innerWidth < 600;

      if (this.isMainPage()) {
        this.disp_cols = 2;
        this.data_cols = 10;
      } else {
        this.disp_cols = 12;
        this.data_cols = 12;
      }
    },
    isMainPage() {
      return this.$route.path === this.path;
    },
    getDispCategories() {
      const categories = [];
      categories.push({
        id: 1,
        disp_name: 'ユーザー管理',
        page: 'UserSettings'
      });
      categories.push({
        id: 2,
        disp_name: 'ユーザーグループ管理',
        page: 'UserGroupSettings'
      });
      categories.push({
        id: 3,
        disp_name: '設備管理',
        page: 'FacilitySettings'
      });
      categories.push({
        id: 4,
        disp_name: '設備グループ管理',
        page: 'FacilityGroupSettings'
      });
      categories.push({
        id: 5,
        disp_name: 'お知らせカテゴリ管理',
        page: 'InformationSettings'
      });
      categories.push({
        id: 6,
        disp_name: 'ライブラリカテゴリ管理',
        page: 'LibrarySettings'
      });
      categories.push({
        id: 7,
        disp_name: 'ワークフロー申請経路管理',
        page: 'WorkflowSettings'
      });
      categories.push({
        id: 8,
        disp_name: 'レイアウト管理',
        page: 'PageSettings'
      });
      categories.push({
        id: 9,
        disp_name: 'タグ管理',
        page: 'TagSettings'
      });
      return categories;
    },
    dispDirectiesChange() {}
  }
};
</script>
