<template>
  <v-card ref="card" class="frame" v-resize="onResize">
    <v-toolbar class="frame" flat>
      <v-btn :to="path" tile text class="pa-0">
        <v-icon v-if="getDispStyle(config.dispStyle).usePageIcon"
          >mdi-timetable</v-icon
        >
        <div class="text-decoration-underline text-body-1">タイムカード</div>
      </v-btn>
      <v-spacer></v-spacer>
    </v-toolbar>
    <template v-if="isMainPage()">
      <v-row class="fill-height">
        <v-col :cols="disp_cols">
          <v-select
            prepend-icon="mdi-tree"
            label="申請区分"
            :items="this.getDispmodeList()"
            item-text="disp_name"
            item-value="id"
            v-model="dispmode"
            style="max-width: 300px"
            dense
            v-if="disp_cols === 12"
            @change="changeDispMode"
            hide-details="auto"
          >
          </v-select>
          <v-list dense v-else>
            <v-list-item-group v-model="dispmode" mandatory color="indigo">
              <v-list-item
                v-for="(item, index) in this.getDispmodeList()"
                :key="index"
                link
                @click="changeDispMode"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.disp_name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col :cols="data_cols">
          <v-row v-if="canShowOther()" no-gutters>
            <v-col :cols="grop_cols">
              <v-select
                item-text="group_name"
                item-value="id"
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-account-multiple'
                    : ''
                "
                :items="getUserGroup()"
                v-model="selectusergroupid"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                hide-details="auto"
                @change="changeDispGroup"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    グループ
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col :cols="user_cols">
              <v-select
                item-text="disp_name"
                item-value="id"
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon ? 'mdi-account' : ''
                "
                :items="getUsers()"
                v-model="selectuserid"
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                hide-details="auto"
                @change="changeDispUser"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    ユーザー
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
          <template v-if="ismobile">
            <v-toolbar flat>
              <v-btn @click="setToday"> 今月 </v-btn>
              {{ monthFormat(selectmonth) }}
              <v-btn @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
            </v-toolbar>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <vue-json-to-csv
                :labels="jsontocsv.labels"
                :json-data="jsontocsv.jsonData"
                :csv-title="jsontocsv.csvTitle"
              >
                <v-btn @click="doDounload"> ダウンロード </v-btn>
              </vue-json-to-csv>
            </v-toolbar>
          </template>
          <template v-else>
            <v-toolbar flat>
              <v-btn @click="setToday"> 今月 </v-btn>
              {{ monthFormat(selectmonth) }}
              <v-btn @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <vue-json-to-csv
                :labels="jsontocsv.labels"
                :json-data="jsontocsv.jsonData"
                :csv-title="jsontocsv.csvTitle"
              >
                <v-btn @click="doDounload"> 表示データのダウンロード </v-btn>
              </vue-json-to-csv>
            </v-toolbar>
          </template>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-center"
                    v-for="(item, index) in getHeaders()"
                    :key="index"
                    v-show="item.visible"
                  >
                    {{ item.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(punch_date, index) in getPunchDates()"
                  :key="index"
                  :style="getDayBackColor(punch_date)"
                >
                  <td
                    class="text-center"
                    style="width: 90px"
                    v-for="(item, index) in getHeaders()"
                    :key="index"
                    v-show="item.visible"
                  >
                    <template v-if="canAddEvent()">
                      <!-- 過去 -->
                      <template v-if="punch_date.getTime() < today.getTime()">
                        <v-btn
                          v-if="item.value === 'punch_date'"
                          @click="startEdit(punch_date)"
                          :disabled="initLoading"
                          text
                          dense
                        >
                          {{ getDispItem(dataList, punch_date, item) }}</v-btn
                        >
                        <template
                          v-else-if="hasDispItem(dataList, punch_date, item)"
                        >
                          <div style="white-space: pre-line">
                            {{ getDispItem(dataList, punch_date, item) }}
                          </div>
                        </template>
                        <template v-else>
                          <v-btn
                            v-if="item.value === 'clock_in_time'"
                            @click="startEdit(punch_date)"
                            :disabled="initLoading"
                            dense
                            >未入力</v-btn
                          >
                          <v-btn
                            v-else-if="item.value === 'clock_out_time'"
                            @click="startEdit(punch_date)"
                            :disabled="initLoading"
                            dense
                            >未入力</v-btn
                          >
                        </template>
                      </template>
                      <!-- 未来 -->
                      <template
                        v-else-if="punch_date.getTime() > today.getTime()"
                      >
                        <v-btn
                          v-if="item.value === 'punch_date'"
                          @click="startEdit(punch_date)"
                          :disabled="initLoading"
                          text
                          dense
                        >
                          {{ getDispItem(dataList, punch_date, item) }}</v-btn
                        >
                        <template
                          v-else-if="hasDispItem(dataList, punch_date, item)"
                        >
                          {{ getDispItem(dataList, punch_date, item) }}
                        </template>
                        <template v-else> </template>
                      </template>
                      <!-- 今日 -->
                      <template v-else>
                        <v-btn
                          v-if="item.value === 'punch_date'"
                          @click="startEdit(punch_date)"
                          :disabled="initLoading"
                          text
                          dense
                        >
                          {{ getDispItem(dataList, punch_date, item) }}</v-btn
                        >
                        <template v-else>
                          <div style="white-space: pre-line">
                            <template
                              v-if="hasDispItem(dataList, punch_date, item)"
                            >
                              {{ getDispItem(dataList, punch_date, item) }}
                            </template>

                            <template
                              v-if="isDispButton(dataList, punch_date, item)"
                            >
                              <v-btn
                                v-if="item.value === 'clock_in_time'"
                                color="info"
                                @click="onClockInTimeClick(punch_date)"
                                dense
                                >出勤</v-btn
                              >
                              <v-btn
                                v-else-if="item.value === 'clock_out_time'"
                                :disabled="
                                  isClockOutDisabled(dataList, punch_date) ||
                                  initLoading
                                "
                                @click="onClockOutTimeClick(punch_date)"
                                dense
                                >退勤</v-btn
                              >
                              <v-btn
                                v-else-if="item.value === 'outgoing_time'"
                                :disabled="
                                  isOutgoingDisabled(dataList, punch_date)
                                "
                                @click="onOutgoingClick(punch_date)"
                                dense
                                >外出</v-btn
                              >
                              <v-btn
                                v-else-if="item.value === 'comeback_time'"
                                :disabled="
                                  isComebackDisabled(dataList, punch_date)
                                "
                                @click="onComebackClick(punch_date)"
                                dense
                                >復帰</v-btn
                              >
                            </template>
                          </div>
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <v-btn
                        v-if="item.value === 'punch_date'"
                        :disabled="initLoading"
                        text
                        dense
                      >
                        {{ getDispItem(dataList, punch_date, item) }}</v-btn
                      >
                      <template
                        v-else-if="hasDispItem(dataList, punch_date, item)"
                      >
                        <div style="white-space: pre-line">
                          {{ getDispItem(dataList, punch_date, item) }}
                        </div>
                      </template>
                      <template v-else> </template>
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-container fill-height>
        <v-row no-gutters>
          <v-col
            cols="3"
            sm="3"
            md="6"
            lg="3"
            v-for="(item, index) in getHeaders()"
            :key="index"
            class="text-center"
          >
            <div style="white-space: pre-line">
              <template v-if="hasDispItem(dataList, getTimeCardToday(), item)">
                <div class="text-center text-caption">{{ item.text }}</div>
                <div
                  class="text-center text-body-2"
                  style="white-space: pre-line"
                >
                  {{ getDispItem(dataList, getTimeCardToday(), item) }}
                </div>
              </template>
              <template v-if="isDispButton(dataList, getTimeCardToday(), item)">
                <v-btn
                  v-if="item.value === 'clock_in_time'"
                  color="info"
                  @click="onClockInTimeClick(getTimeCardToday())"
                  block
                  dense
                  >{{ item.text }}</v-btn
                >
                <v-btn
                  v-else-if="item.value === 'clock_out_time'"
                  :disabled="isClockOutDisabled(dataList, getTimeCardToday())"
                  @click="onClockOutTimeClick(getTimeCardToday())"
                  block
                  dense
                  >{{ item.text }}</v-btn
                >
                <v-btn
                  v-else-if="item.value === 'outgoing_time'"
                  v-show="
                    !hasDispItem(dataList, getTimeCardToday(), item) ||
                    !isOutgoingDisabled(dataList, getTimeCardToday())
                  "
                  :disabled="isOutgoingDisabled(dataList, getTimeCardToday())"
                  @click="onOutgoingClick(getTimeCardToday())"
                  block
                  dense
                  >{{ item.text }}</v-btn
                >
                <v-btn
                  v-else-if="item.value === 'comeback_time'"
                  v-show="
                    !hasDispItem(dataList, getTimeCardToday(), item) ||
                    !isComebackDisabled(dataList, getTimeCardToday())
                  "
                  :disabled="isComebackDisabled(dataList, getTimeCardToday())"
                  @click="onComebackClick(getTimeCardToday())"
                  block
                  dense
                  >{{ item.text }}</v-btn
                >
              </template>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-overlay v-model="isDataLoding" opacity="0" absolute>
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-overlay>
    <custom-dialog v-model="editedOpen" :persistent="true">
      <template v-slot:title>タイムカード編集</template>
      <template v-slot:body>
        <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.reason" dense>
          『 修正理由 』を入力してください。
        </v-alert>
        <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.clock_in_time" dense>
          『 出勤時刻 』を入力してください。
        </v-alert>
        <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.clock_out_time" dense>
          『 退勤時刻 』を入力してください。
        </v-alert>
        <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.comeback_time" dense>
          『 外出時刻 』を入力してください。
        </v-alert>
        <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.outgoing_time" dense>
          『 復帰時刻 』を入力してください。
        </v-alert>
        <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.span_check1" dense>
          『 退勤時刻 』は『 出勤時刻 』以降の時刻を指定してください。
        </v-alert>
        <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.span_check2" dense>
          『 復帰時刻 』は『 外出時刻 』以降の時刻を指定してください。
        </v-alert>
        <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.span_check3" dense>
          『 外出時刻 』は『 出勤時刻 』以降の時刻を指定してください。
        </v-alert>
        <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.span_check4" dense>
          『 復帰時刻 』は『 退勤時刻 』以前の時刻を指定してください。
        </v-alert>
        <v-alert class="pa-0 px-4 py-2 ma-0" type="error" v-if="isError.span_check5" dense>
          『 復帰時刻 』は『 外出時刻 』以降の時刻を指定してください。
        </v-alert>
        <v-container>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="ismobile ? 12 : 4"
              v-show="!getDispStyle(config.dispStyle).useTitleLabel"
            >
              <p class="mb-0">日付</p>
            </v-col>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-text-field
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.punch_date"
                readonly
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    日付
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="ismobile ? 12 : 4"
              v-show="!getDispStyle(config.dispStyle).useTitleLabel"
            >
              <p class="mb-0 required">種類</p>
            </v-col>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-select
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon ? 'mdi-bookmark' : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                item-text="disp_name"
                item-value="id"
                :items="punchStatus"
                v-model="editedEvent.type"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div
                    v-if="getDispStyle(config.dispStyle).useTitleLabel"
                    class="required"
                  >
                    種類
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <template
            v-if="new Date(editedEvent.punch_date).getTime() <= today.getTime()"
          >
            <v-row class="d-flex align-center py-1" no-gutters>
              <v-col
                :cols="ismobile ? 12 : 4"
                v-show="!getDispStyle(config.dispStyle).useTitleLabel"
              >
                <p class="mb-0 required">修正理由</p>
              </v-col>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 12
                    : 8
                "
              >
                <v-textarea
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon
                      ? 'mdi-message-text-outline'
                      : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.reason"
                  counter="100"
                  rows="2"
                  hide-details="auto"
                >
                  <template v-slot:label>
                    <div
                      v-if="getDispStyle(config.dispStyle).useTitleLabel"
                      class="required"
                    >
                      修正理由
                    </div>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row class="d-flex align-center py-1" no-gutters>
              <v-col
                :cols="ismobile ? 12 : 4"
                v-show="!getDispStyle(config.dispStyle).useTitleLabel"
              >
                <p class="mb-0 required">出勤退勤時間</p>
              </v-col>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 6
                    : 4
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? 'mdi-city' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.clock_in_time"
                  :readonly="true"
                  @keydown.up="ClockInTime_UpKeyDown"
                  @keydown.down="ClockInTime_DownKeyDown"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:label>
                    <div
                      v-if="getDispStyle(config.dispStyle).useTitleLabel"
                      class="required"
                    >
                      出勤時間
                    </div>
                  </template>
                  <template v-slot:append-outer>
                    <time-picker v-model="editedEvent.clock_in_time" />
                  </template>
                </v-text-field>
              </v-col>

              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 6
                    : 4
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? '' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.clock_out_time"
                  :readonly="true"
                  @keydown.up="ClockOutTime_UpKeyDown"
                  @keydown.down="ClockOutTime_DownKeyDown"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:label>
                    <div
                      v-if="getDispStyle(config.dispStyle).useTitleLabel"
                      class="required"
                    >
                      退勤時間
                    </div>
                  </template>
                  <template v-slot:append-outer>
                    <time-picker v-model="editedEvent.clock_out_time" />
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex align-center py-1" no-gutters>
              <v-col
                :cols="ismobile ? 12 : 4"
                v-show="!getDispStyle(config.dispStyle).useTitleLabel"
              >
                <p class="mb-0">外出復帰時間</p>
              </v-col>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 6
                    : 4
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? 'mdi-run' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.outgoing_time"
                  :readonly="true"
                  @keydown.up="OutgoingTime_UpKeyDown"
                  @keydown.down="OutgoingTime_DownKeyDown"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:label>
                    <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                      外出時間
                    </div>
                  </template>
                  <template v-slot:append-outer>
                    <time-picker v-model="editedEvent.outgoing_time" />
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 6
                    : 4
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? '' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.comeback_time"
                  :readonly="true"
                  @keydown.up="ComebackTime_UpKeyDown"
                  @keydown.down="ComebackTime_DownKeyDown"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:label>
                    <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                      復帰時間
                    </div>
                  </template>

                  <template v-slot:append-outer>
                    <time-picker v-model="editedEvent.comeback_time" />
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row
              class="d-flex align-center py-1"
              no-gutters
              v-if="
                !!editedEvent.comeback_time ||
                !!editedEvent.outgoing_time2 ||
                !!editedEvent.comeback_time2
              "
            >
              <v-col
                :cols="ismobile ? 12 : 4"
                v-show="!getDispStyle(config.dispStyle).useTitleLabel"
              >
                <p class="mb-0">外出復帰時間２</p>
              </v-col>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 6
                    : 4
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? 'mdi-run' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.outgoing_time2"
                  :readonly="true"
                  @keydown.up="OutgoingTime2_UpKeyDown"
                  @keydown.down="OutgoingTime2_DownKeyDown"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:label>
                    <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                      外出時間２
                    </div>
                  </template>
                  <template v-slot:append-outer>
                    <time-picker v-model="editedEvent.outgoing_time2" />
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 6
                    : 4
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? '' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.comeback_time2"
                  :readonly="true"
                  @keydown.up="ComebackTime2_UpKeyDown"
                  @keydown.down="ComebackTime2_DownKeyDown"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:label>
                    <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                      復帰時間２
                    </div>
                  </template>

                  <template v-slot:append-outer>
                    <time-picker v-model="editedEvent.comeback_time2" />
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row
              class="d-flex align-center py-1"
              no-gutters
              v-if="
                !!editedEvent.comeback_time2 ||
                !!editedEvent.outgoing_time3 ||
                !!editedEvent.comeback_time3
              "
            >
              <v-col
                :cols="ismobile ? 12 : 4"
                v-show="!getDispStyle(config.dispStyle).useTitleLabel"
              >
                <p class="mb-0">外出復帰時間３</p>
              </v-col>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 6
                    : 4
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? 'mdi-run' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.outgoing_time3"
                  :readonly="true"
                  @keydown.up="OutgoingTime3_UpKeyDown"
                  @keydown.down="OutgoingTime3_DownKeyDown"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:label>
                    <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                      外出時間３
                    </div>
                  </template>
                  <template v-slot:append-outer>
                    <time-picker v-model="editedEvent.outgoing_time3" />
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 6
                    : 4
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? '' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.comeback_time3"
                  :readonly="true"
                  @keydown.up="ComebackTime3_UpKeyDown"
                  @keydown.down="ComebackTime3_DownKeyDown"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:label>
                    <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                      復帰時間３
                    </div>
                  </template>

                  <template v-slot:append-outer>
                    <time-picker v-model="editedEvent.comeback_time3" />
                  </template>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row
              class="d-flex align-center py-1"
              no-gutters
              v-if="
                !!editedEvent.comeback_time3 ||
                !!editedEvent.outgoing_time4 ||
                !!editedEvent.comeback_time4
              "
            >
              <v-col
                :cols="ismobile ? 12 : 4"
                v-show="!getDispStyle(config.dispStyle).useTitleLabel"
              >
                <p class="mb-0">外出復帰時間４</p>
              </v-col>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 6
                    : 4
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? 'mdi-run' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.outgoing_time4"
                  :readonly="true"
                  @keydown.up="OutgoingTime4_UpKeyDown"
                  @keydown.down="OutgoingTime4_DownKeyDown"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:label>
                    <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                      外出時間４
                    </div>
                  </template>
                  <template v-slot:append-outer>
                    <time-picker v-model="editedEvent.outgoing_time4" />
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 6
                    : 4
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? '' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.comeback_time4"
                  :readonly="true"
                  @keydown.up="ComebackTime4_UpKeyDown"
                  @keydown.down="ComebackTime4_DownKeyDown"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:label>
                    <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                      復帰時間４
                    </div>
                  </template>

                  <template v-slot:append-outer>
                    <time-picker v-model="editedEvent.comeback_time4" />
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              class="d-flex align-center py-1"
              no-gutters
              v-if="
                !!editedEvent.comeback_time4 ||
                !!editedEvent.outgoing_time5 ||
                !!editedEvent.comeback_time5
              "
            >
              <v-col
                :cols="ismobile ? 12 : 4"
                v-show="!getDispStyle(config.dispStyle).useTitleLabel"
              >
                <p class="mb-0">外出復帰時間５</p>
              </v-col>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 6
                    : 4
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? 'mdi-run' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.outgoing_time5"
                  :readonly="true"
                  @keydown.up="OutgoingTime5_UpKeyDown"
                  @keydown.down="OutgoingTime5_DownKeyDown"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:label>
                    <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                      外出時間５
                    </div>
                  </template>
                  <template v-slot:append-outer>
                    <time-picker v-model="editedEvent.outgoing_time5" />
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                :cols="
                  getDispStyle(config.dispStyle).useTitleLabel || ismobile
                    ? 6
                    : 4
                "
              >
                <v-text-field
                  :prepend-icon="
                    getDispStyle(config.dispStyle).useIcon ? '' : ''
                  "
                  :outlined="getDispStyle(config.dispStyle).outlined"
                  :dense="dense"
                  v-model="editedEvent.comeback_time5"
                  :readonly="true"
                  @keydown.up="ComebackTime5_UpKeyDown"
                  @keydown.down="ComebackTime5_DownKeyDown"
                  hide-details="auto"
                  clearable
                >
                  <template v-slot:label>
                    <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                      復帰時間５
                    </div>
                  </template>

                  <template v-slot:append-outer>
                    <time-picker v-model="editedEvent.comeback_time5" />
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </template>
          <v-row class="d-flex align-center py-1" no-gutters>
            <v-col
              :cols="ismobile ? 12 : 4"
              v-show="!getDispStyle(config.dispStyle).useTitleLabel"
            >
              <p class="mb-0">備考</p>
            </v-col>
            <v-col
              :cols="
                getDispStyle(config.dispStyle).useTitleLabel || ismobile
                  ? 12
                  : 8
              "
            >
              <v-textarea
                :prepend-icon="
                  getDispStyle(config.dispStyle).useIcon
                    ? 'mdi-message-text-outline'
                    : ''
                "
                :outlined="getDispStyle(config.dispStyle).outlined"
                :dense="dense"
                v-model="editedEvent.remarks"
                counter="100"
                rows="2"
                hide-details="auto"
              >
                <template v-slot:label>
                  <div v-if="getDispStyle(config.dispStyle).useTitleLabel">
                    備考
                  </div>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn color="info" @click="endEdit" v-html="'登録'"> </v-btn>
        <v-btn @click="cancelEdit"> キャンセル </v-btn>
      </template>
    </custom-dialog>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import VueJsonToCsv from 'vue-json-to-csv';
import TimePicker from '../components/TimePicker';
import CustomDialog from '../components/CustomDialog.vue';
import dateUtils from '../functions/DateUtils';
import dateformat from '../functions/DateFormat';
import fileOperation from '../../utils/fileOperation';
import token from '../../utils/token';
import inputStyleUtils from '../../utils/inputStyleUtils';
import scroll from '../../utils/scroll';

export default {
  components: {
    VueJsonToCsv,
    TimePicker,
    CustomDialog
  },
  data: () => ({
    name: 'timecard',
    path: '/timecard',
    config: { start_time: '8:00' },
    dense: true,
    ismobile: false,
    punchStatus: [
      { id: 1, disp_name: '出勤', requiredTime: true },
      { id: 2, disp_name: '欠勤', requiredTime: false },
      { id: 3, disp_name: '有給', requiredTime: false },
      { id: 4, disp_name: '午前休', requiredTime: true },
      { id: 5, disp_name: '午後休', requiredTime: true },
      { id: 6, disp_name: '代休', requiredTime: false },
      { id: 7, disp_name: 'その他', requiredTime: false }
    ],
    dispmode: 0,
    isDataLoding: false,
    dataList: [],
    today: dateUtils.getToday(),
    selectmonth: dateUtils.addDay(
      dateUtils.getToday(),
      -dateUtils.getDay(dateUtils.getToday()) + 1
    ),
    selectusergroupid: -1,
    selectuserid: -1,
    windowSize: {
      x: 0,
      y: 0
    },
    cardSize: {
      x: 0,
      y: 0
    },
    disp_cols: 2,
    data_cols: 10,
    grop_cols: 4,
    user_cols: 4,
    isError: {
      reason: false,
      clock_in_time: false,
      clock_out_time: false,
      comeback_time: false,
      outgoing_time: false,
      span_check1: false,
      span_check2: false,
      span_check3: false,
      span_check4: false,
      span_check5: false
    },
    editedOpen: false,
    editedEvent: {},
    jsontocsv: {
      labels: {},
      jsonData: [],
      csvTitle: ''
    }
  }),
  created: function () {
    if (!this.initLoading) {
      this.init();
    }
  },
  watch: {
    initLoading(val, old) {
      console.log('watch', val, old);
      if (!val) {
        this.init();
      }
    },
    reLoading() {
      this.reload();
    }
  },
  computed: {
    ...mapState({
      initLoading: state => state.initLoading,
      reLoading: state => state.reLoading,
      usersList: state => state.user.usersList,
      usergroup: state => state.userGroup.usergroup,
      filedata: state => state.fileData.filedata,
      userConfig: state => state.userConfig.userconfig,
      holidayData: state => state.holiday.holidayData,
      timecard: state => state.timecard.timecard
    })
  },
  mixins: [fileOperation, token, inputStyleUtils, scroll],
  methods: {
    ...mapActions([
      'fetchUserConfig',
      'upsertUserConfig',
      'fetchTimeCardData',
      'insertTimeCardData',
      'updateTimeCardData',
      'setTimeCardClockIn',
      'setTimeCardClockOut',
      'setTimeCardOutgoing',
      'setTimeCardComeback'
    ]),
    init() {
      let config = this.userConfig.find(
        item => item.user_id === this.getUserId()
      );
      this.config.start_time = config.start_time || '08:00';
      this.config.dispStyle = config.dispStyle || 1;

      this.today = this.getTimeCardToday();
      this.selectmonth = dateUtils.addDay(
        this.today,
        -dateUtils.getDay(this.today) + 1
      );
      this.selectusergroupid =
        this.usergroup.find(x => x.user_id.find(y => y == this.getUserId()))
          ?.id || -1;
      this.selectuserid = this.getUserId();

      this.dispTimeCardData();
    },
    reload() {
      //データ取得
      this.dispTimeCardData();
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.iconSize = window.innerHeight * 0.1;
      let temp = this.$refs.card.$el;
      this.cardSize = { x: temp.clientWidth, y: temp.clientHeight };

      this.ismobile = window.innerWidth < 600;

      //ブレイクポイントはウィンドウの連続した操作に弱い
      if (this.isMainPage() && !(window.innerWidth < 960)) {
        [this.disp_cols, this.data_cols] = [2, 10];
        [this.grop_cols, this.user_cols] = [4, 4];
      } else {
        [this.disp_cols, this.data_cols] = [12, 12];
        [this.grop_cols, this.user_cols] = [6, 6];
      }
    },
    isMainPage() {
      return this.$route.path === '/' + this.name;
    },
    getItemsPerPage() {
      return `${this.name}_items_per_page_${
        this.isMainPage() ? 'main' : 'sub'
      }`;
    },
    getDispmodeList() {
      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.TIMECARD,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.VIEW
      );
      const ret = [];
      if (mePermission) {
        ret.push({ id: 0, disp_name: 'タイムカード記録' });
      } else {
        ret.push({ id: 0, disp_name: 'タイムカード記録' });
      }
      return ret;
    },
    getUserGroup() {
      return [{ id: -1, group_name: '全て' }, ...this.usergroup];
    },
    getUsers() {
      const usersList = this.usersList.filter(x => x.enable);
      if (this.selectusergroupid == -1) {
        return usersList;
      } else {
        const group = this.usergroup.find(x => x.id == this.selectusergroupid);
        const subUsersList = usersList.filter(x =>
          group.user_id.find(y => y == x.id)
        );
        if (!subUsersList.find(x => x.id == this.selectuserid)) {
          this.selectuserid = subUsersList[0]?.id || -1;
        }

        return subUsersList;
      }
    },
    getHeaders() {
      const punch_date = {
        text: '日付',
        value: 'punch_date',
        visible: true,
        format: x => x
      };
      punch_date.format = x => this.dateFormat(x);
      const style = {
        text: '勤務形態',
        value: 'style',
        visible: false,
        format: x => x
      };
      style.format = x => this.styleFormat(x);
      const type = {
        text: '種類',
        value: 'type',
        visible: true,
        format: x => x
      };
      type.format = x => this.typeFormat(x);
      const clock_in_time = {
        text: '出勤',
        value: 'clock_in_time',
        visible: true,
        format: x => x
      };
      clock_in_time.format = x => this.clockFormat(x);
      const clock_out_time = {
        text: '退勤',
        value: 'clock_out_time',
        visible: true,
        format: x => x
      };
      clock_out_time.format = x => this.clockFormat(x);
      const outgoing_time = {
        text: '外出',
        value: 'outgoing_time',
        visible: true,
        format: x => x
      };
      outgoing_time.format = x => this.clockFormat(x);
      const comeback_time = {
        text: '復帰',
        value: 'comeback_time',
        visible: true,
        format: x => x
      };
      comeback_time.format = x => this.clockFormat(x);
      const reason = {
        text: '修正理由',
        value: 'reason',
        visible: true,
        format: x => x
      };
      const remarks = {
        text: '備考',
        value: 'remarks',
        visible: true,
        format: x => x
      };
      if (this.isMainPage()) {
        if (this.cardSize.x < 600) {
          return [punch_date, clock_in_time, clock_out_time];
        } else if (this.cardSize.x < 1000) {
          return [
            punch_date,
            clock_in_time,
            clock_out_time,
            outgoing_time,
            comeback_time
          ];
        } else if (this.cardSize.x < 1200) {
          return [
            punch_date,
            style,
            type,
            clock_in_time,
            clock_out_time,
            outgoing_time,
            comeback_time,
            reason,
            remarks
          ];
        } else {
          return [
            punch_date,
            style,
            type,
            clock_in_time,
            clock_out_time,
            outgoing_time,
            comeback_time,
            reason,
            remarks
          ];
        }
      } else {
        return [clock_in_time, clock_out_time, outgoing_time, comeback_time];
      }
    },
    setToday() {
      this.today = this.getTimeCardToday();
      this.selectmonth = dateUtils.addDay(
        this.today,
        -dateUtils.getDay(this.today) + 1
      );
      this.dispTimeCardData();
    },
    prev() {
      this.selectmonth = dateUtils.addMonth(this.selectmonth, -1);
      this.dispTimeCardData();
    },
    next() {
      this.selectmonth = dateUtils.addMonth(this.selectmonth, 1);
      this.dispTimeCardData();
    },

    getPunchDates() {
      const result = [];
      const baseDate = this.selectmonth;
      const endDate = dateUtils.addMonth(baseDate, 1);
      let addDay = 0;

      while (dateUtils.addDay(baseDate, addDay) < endDate) {
        result.push(dateUtils.addDay(baseDate, addDay));
        addDay++;
      }
      return result;
    },
    canAddEvent() {
      //新規登録可否
      if (this.selectuserid == this.getUserId()) {
        return this.getPermission(
          this.TOKEN_FEATURE.TIMECARD,
          this.TOKEN_OWNER.ME,
          this.TOKEN_ACTION.EDIT
        );
      } else {
        return this.getPermission(
          this.TOKEN_FEATURE.TIMECARD,
          this.TOKEN_OWNER.OTHER,
          this.TOKEN_ACTION.EDIT
        );
      }
    },
    canShowOther() {
      // const otherPermission = this.getPermission(
      //   this.TOKEN_FEATURE.TIMECARD,
      //   this.TOKEN_OWNER.OTHER,
      //   this.TOKEN_ACTION.VIEW
      // );
      const otherPermission = false;
      //とりあえず管理者は他ユーザーのデータを参照可能とする
      return (
        otherPermission ||
        this.usersList.find(item => item.id === this.getUserId())?.admin
      );
    },

    canEditEvent(event) {
      let result = false;
      if (!event.insertuser) return false;

      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.TIMECARD,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.EDIT
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.TIMECARD,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.EDIT
      );

      if (event.insertuser === this.getUserId()) {
        result = mePermission;
      } else {
        result = otherPerMission;
      }
      //編集権限のないフォルダ
      if (!this.getEditDirectories().find(x => x.id === event.directry_id)) {
        result = false;
      }
      return result;
    },
    canDeleteEvent(event) {
      let result = false;
      if (!event.insertuser) return false;
      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.TIMECARD,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.DELETE
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.TIMECARD,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.DELETE
      );
      if (event.insertuser === this.getUserId()) {
        result = mePermission;
      } else {
        result = otherPerMission;
      }
      //編集権限のないフォルダ
      if (!this.getEditDirectories().find(x => x.id === event.directry_id)) {
        result = false;
      }

      return result;
    },

    startEdit(punch_date) {
      const item = this.findDayItem(this.dataList, punch_date);
      if (!item) {
        const data = this.createEmptyData();
        data.punch_date = punch_date;
        data.type = 1;

        this.editedEvent = this.createEditData(data);
      } else {
        this.editedEvent = this.createEditData(item);
      }

      this.errrorReset();
      this.editedOpen = true;
    },
    errrorReset() {
      for (var key in this.isError) {
        this.isError[key] = false;
      }
    },
    inputCheck() {
      this.errrorReset();

      const punch_date = new Date(this.editedEvent.punch_date);
      const timeFormat = this.timeTextToDate;
      const startTimeConv = x => {
        const start_time = timeFormat(this.config.start_time);
        if (x && x < start_time) {
          x = dateUtils.addDay(x, 1);
        }
        return x;
      };
      const clock_in_time = startTimeConv(
        timeFormat(this.editedEvent.clock_in_time)
      );
      const clock_out_time = startTimeConv(
        timeFormat(this.editedEvent.clock_out_time)
      );
      const outgoing_time = [
        startTimeConv(timeFormat(this.editedEvent.outgoing_time)),
        startTimeConv(timeFormat(this.editedEvent.outgoing_time2)),
        startTimeConv(timeFormat(this.editedEvent.outgoing_time3)),
        startTimeConv(timeFormat(this.editedEvent.outgoing_time4)),
        startTimeConv(timeFormat(this.editedEvent.outgoing_time5))
      ];
      const comeback_time = [
        startTimeConv(timeFormat(this.editedEvent.comeback_time)),
        startTimeConv(timeFormat(this.editedEvent.comeback_time2)),
        startTimeConv(timeFormat(this.editedEvent.comeback_time3)),
        startTimeConv(timeFormat(this.editedEvent.comeback_time4)),
        startTimeConv(timeFormat(this.editedEvent.comeback_time5))
      ];

      const type = this.punchStatus.find(x => x.id === this.editedEvent.type);

      //チェック対象は今日以前
      if (punch_date <= this.today) {
        //修正理由の入力があるか
        this.isError.reason =
          !this.editedEvent.reason || this.editedEvent.reason.length === 0;
      }

      //出勤・午前休・午後休の場合、出退勤の入力をチェックする
      if (type.requiredTime) {
        //チェック対象は昨日以前
        if (punch_date < this.today) {
          this.isError.clock_in_time |= !clock_in_time;
          this.isError.clock_out_time |= !clock_out_time;
        }
      }

      //出勤が入力されているか
      if (clock_out_time) {
        this.isError.clock_in_time |= !clock_in_time;
      }
      //退勤が出勤よりも後か
      if (clock_out_time && clock_in_time) {
        this.isError.span_check1 |= clock_out_time < clock_in_time;
      }
      for (var i = 0; i < 4; i++) {
        //復帰が入力されているか
        if (outgoing_time[i]) {
          this.isError.outgoing_time |= !comeback_time[i];
        }
        //外出が入力されているか
        if (comeback_time[i]) {
          this.isError.comeback_time |= !outgoing_time[i];
        }

        //退勤が外出よりも後か
        if (clock_out_time && outgoing_time[i]) {
          this.isError.span_check2 |= clock_out_time < outgoing_time[i];
        }

        //外出が出勤よりも後か
        if (clock_in_time && outgoing_time[i]) {
          this.isError.span_check3 |= outgoing_time[i] < clock_in_time;
        }

        //復帰が退勤よりも後か
        if (clock_out_time && comeback_time[i]) {
          this.isError.span_check4 |= clock_out_time < comeback_time[i];
        }

        //退勤が出勤よりも後か
        if (outgoing_time[i] && comeback_time[i]) {
          this.isError.span_check5 |= comeback_time[i] < outgoing_time[i];
        }
      }
      //TODO:外出範囲での相関チェック

      for (var key in this.isError) {
        if (this.isError[key]) {
          return false;
        }
      }
      return true;
    },
    endEdit() {
      //入力チェック
      if (!this.inputCheck()) {
        this.doScrollTop();
        return;
      }

      const data = this.createRegistData(this.editedEvent);

      let p = undefined;
      if (data.id === -1) {
        data.id = undefined;
        p = this.insertTimeCardData(data);
      } else {
        p = this.updateTimeCardData(data);
      }

      p.then(() => {
        this.dispTimeCardData();
        this.editedOpen = false;
      });
    },
    cancelEdit() {
      this.editedOpen = false;
    },

    changeDispMode() {
      this.dispTimeCardData();
    },
    changeDispUser() {
      this.dispTimeCardData();
    },
    changeDispGroup() {
      this.dispTimeCardData2();
    },
    dispTimeCardData() {
      //再表示時に今日の日付を更新
      this.today = this.getTimeCardToday();
      //データ取得中
      this.isDataLoding = true;

      //タイムカードの情報取得
      const data = {
        user_id: this.selectuserid,
        punch_date: {
          gte: this.selectmonth,
          lte: dateUtils.addMonth(this.selectmonth, 1)
        }
      };

      this.fetchTimeCardData(data).then(() => {
        if (this.timecard) {
          this.dataList = this.timecard.filter(x => this.isDispItem(x));
          // this.dataList = this.timecard;
        }

        //データ取得完了
        this.isDataLoding = false;
      });
    },
    dispTimeCardData2() {
      //再表示時に今日の日付を更新
      this.today = this.getTimeCardToday();
      //データ取得中
      this.isDataLoding = true;

      const userlist = this.getUsers();
      let dispUserId = this.selectuserid;
      if (userlist.find(x => x.id === dispUserId) === undefined) {
        dispUserId = userlist[0]?.id || -1;
      }

      //タイムカードの情報取得
      const data = {
        user_id: dispUserId,
        punch_date: {
          gte: this.selectmonth,
          lte: dateUtils.addMonth(this.selectmonth, 1)
        }
      };

      this.fetchTimeCardData(data).then(() => {
        if (this.timecard) {
          this.dataList = this.timecard.filter(x => this.isDispItem(x));
          // this.dataList = this.timecard;
        }

        //データ取得完了
        this.isDataLoding = false;
      });
    },
    isDispItem(item) {
      const mePermission = this.getPermission(
        this.TOKEN_FEATURE.TIMECARD,
        this.TOKEN_OWNER.ME,
        this.TOKEN_ACTION.VIEW
      );
      const otherPerMission = this.getPermission(
        this.TOKEN_FEATURE.TIMECARD,
        this.TOKEN_OWNER.OTHER,
        this.TOKEN_ACTION.VIEW
      );
      if (item.user_id === this.getUserId()) {
        return mePermission;
      } else {
        return otherPerMission;
      }
    },
    saveConfig() {
      //選択状態を保存する
      let promise = this.fetchUserConfig();
      promise = promise
        .then(() => {
          let config = this.userConfig.find(
            item => item.user_id === this.getUserId()
          );

          if (!config) {
            config = {};
            config.user_id = this.getUserId();
          }
          config[`${this.name}_directry_id`] =
            this.config[`${this.name}_directry_id`];
          return this.upsertUserConfig(config);
        })
        .catch(err => {
          console.log(err);
        });
      promise.catch(err => {
        console.log(err);
      });
      return promise;
    },
    onClockInTimeClick(punch_date) {
      this.setTimeCardClockIn({
        user_id: this.selectuserid,
        punch_date: punch_date
      }).then(() => {
        this.dispTimeCardData();
      });
    },
    onClockOutTimeClick(punch_date) {
      this.setTimeCardClockOut({
        user_id: this.selectuserid,
        punch_date: punch_date
      }).then(() => {
        this.dispTimeCardData();
      });
    },
    onOutgoingClick(punch_date) {
      this.setTimeCardOutgoing({
        user_id: this.selectuserid,
        punch_date: punch_date
      }).then(() => {
        this.dispTimeCardData();
      });
    },
    onComebackClick(punch_date) {
      this.setTimeCardComeback({
        user_id: this.selectuserid,
        punch_date: punch_date
      }).then(() => {
        this.dispTimeCardData();
      });
    },
    createEmptyData() {
      return {
        id: -1,
        user_id: this.selectuserid,
        punch_date: undefined,
        style: -1,
        type: -1,
        clock_in_time: undefined,
        clock_out_time: undefined,
        outgoing_time: undefined,
        comeback_time: undefined,
        reason: '',
        remarks: '',
        insertuser: this.getUserId(),
        inserttime: dateUtils.getNow(),
        updateuser: this.getUserId(),
        updatetime: dateUtils.getNow()
      };
    },
    createEditData(item) {
      const data = JSON.parse(JSON.stringify(item));
      const timeFormat = this.timeDateToText;

      data.punch_date = dateformat.format(data.punch_date, 'YYYY-MM-DD');
      data.clock_in_time = timeFormat(data.clock_in_time);
      data.clock_out_time = timeFormat(data.clock_out_time);
      data.outgoing_time = timeFormat(data.outgoing_time);
      data.comeback_time = timeFormat(data.comeback_time);
      data.outgoing_time2 = timeFormat(data.outgoing_time2);
      data.comeback_time2 = timeFormat(data.comeback_time2);
      data.outgoing_time3 = timeFormat(data.outgoing_time3);
      data.comeback_time3 = timeFormat(data.comeback_time3);
      data.outgoing_time4 = timeFormat(data.outgoing_time4);
      data.comeback_time4 = timeFormat(data.comeback_time4);
      data.outgoing_time5 = timeFormat(data.outgoing_time5);
      data.comeback_time5 = timeFormat(data.comeback_time5);
      return data;
    },
    timeDateToText(x) {
      if (x) {
        return dateformat.format(x, 'HH:mm');
      } else {
        return '';
      }
    },
    createRegistData(item) {
      const data = JSON.parse(JSON.stringify(item));
      const timeFormat = this.timeTextToDate;

      data.punch_date = dateUtils.getDate(data.punch_date);
      data.clock_in_time = timeFormat(data.clock_in_time);
      data.clock_out_time = timeFormat(data.clock_out_time);
      const timePair = [
        {
          outgoing_time: timeFormat(data.outgoing_time),
          comeback_time: timeFormat(data.comeback_time)
        },
        {
          outgoing_time: timeFormat(data.outgoing_time2),
          comeback_time: timeFormat(data.comeback_time2)
        },
        {
          outgoing_time: timeFormat(data.outgoing_time3),
          comeback_time: timeFormat(data.comeback_time3)
        },
        {
          outgoing_time: timeFormat(data.outgoing_time4),
          comeback_time: timeFormat(data.comeback_time4)
        },
        {
          outgoing_time: timeFormat(data.outgoing_time5),
          comeback_time: timeFormat(data.comeback_time5)
        }
      ].filter(x => x.comeback_time);

      data.outgoing_time = timePair[0]?.outgoing_time ?? null;
      data.comeback_time = timePair[0]?.comeback_time ?? null;
      data.outgoing_time2 = timePair[1]?.outgoing_time ?? null;
      data.comeback_time2 = timePair[1]?.comeback_time ?? null;
      data.outgoing_time3 = timePair[2]?.outgoing_time ?? null;
      data.comeback_time3 = timePair[2]?.comeback_time ?? null;
      data.outgoing_time4 = timePair[3]?.outgoing_time ?? null;
      data.comeback_time4 = timePair[3]?.comeback_time ?? null;
      data.outgoing_time5 = timePair[4]?.outgoing_time ?? null;
      data.comeback_time5 = timePair[4]?.comeback_time ?? null;

      return data;
    },
    timeTextToDate(x) {
      if (x && x.match(/:/)) {
        let [hh, mm] = x.split(':');
        return dateUtils.getTime2(parseInt(hh), parseInt(mm), 0);
      } else {
        return null;
      }
    },
    getTimeCardToday() {
      //始業単位より前の場合は前日扱いとする
      let [hh, mm] = this.config.start_time.split(':');
      let toTime = dateUtils.getTime();
      let maxTime = dateUtils.getTime2(parseInt(hh), parseInt(mm), 0);
      if (toTime >= maxTime) {
        return dateUtils.getToday();
      } else {
        return dateUtils.addDay(dateUtils.getToday(), -1);
      }
    },
    findDayItem(dataList, punch_date) {
      if (dataList && dataList.length > 0) {
        return dataList.find(
          x => new Date(x.punch_date).getTime() === punch_date.getTime()
        );
      } else {
        return null;
      }
    },
    hasDispItem(dataList, punch_date, headerItem) {
      const item = this.findDayItem(dataList, punch_date);
      const value = item?.[headerItem.value];
      return !!value;
    },
    isDispButton(dataList, punch_date, headerItem) {
      const item = this.findDayItem(dataList, punch_date);
      if (
        headerItem.value == 'outgoing_time' ||
        headerItem.value == 'comeback_time'
      ) {
        const value = item?.[headerItem.value + '5'];
        return !value;
      } else {
        const value = item?.[headerItem.value];
        return !value;
      }
    },
    getDispItem(dataList, punch_date, headerItem) {
      const item = this.findDayItem(dataList, punch_date);
      if (headerItem.value === 'punch_date') {
        return headerItem.format(punch_date);
      } else {
        var result = '';
        for (var i = 1; i <= 5; i++) {
          const value = item?.[headerItem.value + (i > 1 ? i : '')];
          if (value) {
            // if (result != '') result += '\n';
            result += headerItem.format(value);
            result += '\n';
          }
        }
        return result;
      }
    },
    isClockOutDisabled(dataList, punch_date) {
      const item = this.findDayItem(dataList, punch_date);
      //未出勤
      if (!item?.clock_in_time) return true;

      //未復帰
      if (!!item?.outgoing_time && !item?.comeback_time) return true;
      if (!!item?.outgoing_time2 && !item?.comeback_time2) return true;
      if (!!item?.outgoing_time3 && !item?.comeback_time3) return true;
      if (!!item?.outgoing_time4 && !item?.comeback_time4) return true;
      if (!!item?.outgoing_time5 && !item?.comeback_time5) return true;

      return false;
    },
    isOutgoingDisabled(dataList, punch_date) {
      const item = this.findDayItem(dataList, punch_date);
      //出勤データなし
      if (!item) return true;
      //未出勤
      if (!item?.clock_in_time) return true;
      //退勤
      if (item?.clock_out_time) return true;

      //直前の外出が戻ってきているか
      if (!item.outgoing_time) {
        return false;
      } else if (!item.outgoing_time2) {
        return !item.comeback_time;
      } else if (!item.outgoing_time3) {
        return !item.comeback_time2;
      } else if (!item.outgoing_time4) {
        return !item.comeback_time3;
      } else if (!item.outgoing_time5) {
        return !item.comeback_time4;
      } else {
        return true;
      }
    },
    isComebackDisabled(dataList, punch_date) {
      const item = this.findDayItem(dataList, punch_date);
      //出勤データなし
      if (!item) return true;
      //未出勤
      if (!item?.clock_in_time) return true;
      //退勤
      if (item?.clock_out_time) return true;
      // //未外出
      // if (!item?.outgoing_time) return true;

      //帰宅しているか
      if (!!item.outgoing_time && !item.comeback_time) {
        return false;
      } else if (!!item.outgoing_time2 && !item.comeback_time2) {
        return false;
      } else if (!!item.outgoing_time3 && !item.comeback_time3) {
        return false;
      } else if (!!item.outgoing_time4 && !item.comeback_time4) {
        return false;
      } else if (!!item.outgoing_time5 && !item.comeback_time5) {
        return false;
      } else {
        return true;
      }
    },
    monthFormat(date) {
      return dateformat.format(date, 'YYYY年MM月');
    },
    dateFormat(date) {
      if (this.ismobile) {
        return dateformat.format(date, 'D日(ddd)');
      } else {
        return dateformat.format(date, 'M月D日(ddd)');
      }
    },
    styleFormat(style) {
      switch (style) {
        case 0:
          return 'A';
        case 1:
          return 'B';
        case 2:
          return 'C';
        default:
          return '自社';
      }
    },
    typeFormat(type) {
      return this.punchStatus.find(x => x.id === type)?.disp_name || '';
    },
    clockFormat(time) {
      if (time) {
        return dateformat.format(time, 'HH:mm');
      } else {
        return '-';
      }
    },
    getDayBackColor(date) {
      if (
        this.holidayData.find(
          x => new Date(x.day_date).getTime() === date.getTime()
        )
      ) {
        return 'background: rgba(255,0,0,0.1)';
      } else if (date.getDay() === 0) {
        return 'background: rgba(255,0,0,0.1)';
      } else if (date.getDay() === 6) {
        return 'background: rgba(0,255,255,0.1)';
      } else {
        return '';
      }
    },
    doDounload() {
      const jsontocsv = this.jsontocsv;

      jsontocsv.labels = {
        user_name: { title: '氏名' },
        punch_date: { title: '日付' },
        punch_week: { title: '曜日' },
        type: { title: '種類' },
        clock_in_time: { title: '出勤時間' },
        clock_out_time: { title: '退勤時間' },
        outgoing_time: { title: '外出1' },
        comeback_time: { title: '復帰1' },
        outgoing_time2: { title: '外出2' },
        comeback_time2: { title: '復帰2' },
        outgoing_time3: { title: '外出3' },
        comeback_time3: { title: '復帰3' },
        outgoing_time4: { title: '外出4' },
        comeback_time4: { title: '復帰4' },
        outgoing_time5: { title: '外出5' },
        comeback_time5: { title: '復帰5' },
        reason: { title: '修正理由' },
        remarks: { title: '備考' }
      };
      const punchDates = this.getPunchDates();
      const user_name = this.usersList.find(
        x => x.id === this.selectuserid
      ).disp_name;

      const data = punchDates.map(punch_date => {
        const item = this.findDayItem(this.dataList, punch_date);

        return {
          user_name: user_name,
          punch_date: dateformat.format(punch_date, 'YYYY-MM-DD'),
          punch_week: dateformat.format(punch_date, 'ddd'),
          type: this.typeFormat(item?.type),
          clock_in_time: this.timeDateToText(item?.clock_in_time),
          clock_out_time: this.timeDateToText(item?.clock_out_time),
          outgoing_time: this.timeDateToText(item?.outgoing_time),
          comeback_time: this.timeDateToText(item?.comeback_time),
          outgoing_time2: this.timeDateToText(item?.outgoing_time2),
          comeback_time2: this.timeDateToText(item?.comeback_time2),
          outgoing_time3: this.timeDateToText(item?.outgoing_time3),
          comeback_time3: this.timeDateToText(item?.comeback_time3),
          outgoing_time4: this.timeDateToText(item?.outgoing_time4),
          comeback_time4: this.timeDateToText(item?.comeback_time4),
          outgoing_time5: this.timeDateToText(item?.outgoing_time5),
          comeback_time5: this.timeDateToText(item?.comeback_time5),
          reason: item?.reason || '',
          remarks: item?.remarks || ''
        };
      });
      jsontocsv.jsonData = data;

      jsontocsv.csvTitle =
        dateformat.format(this.selectmonth, 'YYYY') +
        '年' +
        dateformat.format(this.selectmonth, 'MM') +
        '月分' +
        user_name;
    }
  }
};
</script>
<style scoped>
.required::after {
  content: '必須';
  color: red;
  font-size: 12px;
  font-weight: bold;
  min-width: 10px;
  padding: 0px 0px;
  margin: 0px 5px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
}
.v-btn--active::before {
  opacity: 0 !important;
}
</style>
