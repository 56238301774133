<template>
  <footer style="text-align: right">
    <small class="grey--text">{{ footer_text }}</small>
  </footer>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Footer',
  components: {},
  data: () => ({
    windowSize: {
      x: 0,
      y: 0
    },
    name: require('../../package.json').name,
    version: require('../../package.json').version,
    copyright:
      'Copyright © 2022-2023 株式会社ソフトウェア開発技術 All rights Reserved.',
    footer_text: ''
  }),

  created: function () {},
  mounted: function () {
    //Aipo 8.1.1.0 ～Customize By Software Development Technologies～Ver1.2
    let var_name =
      this.getAppName() +
      ' ' +
      this.getAppVersion() +
      ' ' +
      this.getAppCopyRight();

    this.footer_text = var_name;
  },

  methods: {
    ...mapActions([]),
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    getAppName() {
      var value = process.env.VUE_APP_DISP_NAME ?? '';
      return value.length > 0 ? value : this.name;
    },
    getAppVersion() {
      var value = process.env.VUE_APP_DISP_VERSION ?? '';
      return value.length > 0 ? value : this.version;
    },
    getAppCopyRight() {
      var value = process.env.VUE_APP_DISP_COPYRIGHT ?? '';
      return value.length > 0 ? value : this.copyright;
    }
  }
};
</script>
