import http from '../../utils/httpClient';

const state = {
  push_public_key:''
};

const mutations = {
  setPushPublicKey(state, value) {
    console.log(state);
    console.log(value);
    state.push_public_key = value?.public_key || 'ねこ';
  }
};

const actions = {
  async fetchPushPublicKey(context) {
    await http.get('systemsettings/push_public_key' ).then(res => {
      console.log('fetchPushPublicKey');
      console.log(res.data);
      context.commit('setPushPublicKey', res.data);
    });
  },
  
  /////////////////////////////////////////////////////////////
};

export default {
  state,
  mutations,
  actions
};
