<template>
  <div v-resize="setLayout">
    <template v-if="config.splitType === 1">
      <v-row v-for="(item, index) in config.Panel1" :key="index">
        <v-col> <component :is="item" /></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-row v-for="(item, index) in config.Panel2" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="9">
          <v-row v-for="(item, index) in config.Panel3" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="config.splitType === 2">
      <v-row>
        <v-col cols="12" md="3">
          <v-row v-for="(item, index) in config.Panel1" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="9">
          <v-row v-for="(item, index) in config.Panel2" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="config.splitType === 3">
      <v-row>
        <v-col cols="12" md="3">
          <v-row v-for="(item, index) in config.Panel1" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row v-for="(item, index) in config.Panel2" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="3">
          <v-row v-for="(item, index) in config.Panel3" :key="index">
            <v-col> <component :is="item" /></v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import Schedule from '../pages/Schedule.vue';
import Library from '../pages/Library.vue';
import Information from '../pages/Information.vue';
// import Workflow from '../pages/Workflow.vue';
import { mapState, mapActions } from 'vuex';
import Workflow from '../pages/Workflow2.vue';
import TimeCard from '../pages/TimeCard.vue';
import Mail from '../pages/Mail.vue';
import token from '../../utils/token';

export default {
  components: {
    Schedule,
    Library,
    Workflow,
    Information,
    TimeCard,
    Mail
  },
  data: () => ({
    config: {
      splitType: 1,
      Panel1: [],
      Panel2: [],
      Panel3: []
    },
    windowSize: {
      x: 0
    }
  }),
  mounted() {
    this.init();
    // NOTE: 初期状態 = splitType: 1
    // this.config = {
    //   splitType: 1,
    //   Panel1: ['Information'],
    //   Panel2: ['TimeCard', 'Library', 'Workflow'],
    //   Panel3: ['Schedule']
    // };
    // this.config = {
    //   splitType: 2,
    //   Panel1: ['TimeCard', 'Information', 'Library', 'Workflow'],
    //   Panel2: ['Schedule'],
    //   Panel3: []
    // };
    // this.config = {
    //   splitType: 3,
    //   Panel1: ['TimeCard', 'Workflow'],
    //   Panel2: ['Schedule'],
    //   Panel3: ['Information', 'Library']
    // };
  },
  created: function () {},
  computed: {
    ...mapState({
      pageSetting: state => state.pagesetting.pagesetting,
      feature: state => state.feature.feature
    })
  },
  mixins: [token],
  methods: {
    ...mapActions(['fetchUserList', 'fetchPageSetting']),
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    init() {
      const p = [];
      // レイアウト設定の取得
      p.push(this.fetchPageSetting(this.getUserId()));

      Promise.all(p).then(() => {
        this.setLayout();
      });
    },
    setLayout() {
      this.windowSize = { x: window.innerWidth };
      // スクリーン幅が 600px 以下の場合、モバイルレイアウトを適用
      const layout =
        this.windowSize.x < 600
          ? this.pageSetting.filter(x => x.page_num === 0)[0]
          : this.pageSetting.filter(x => x.page_num === 1)[0];

      // TypeError がでるのでnull許容をつける(表示には影響なし)
      this.config.splitType = layout?.split_type;

      // 各パネルに表示するコンポーネントを取得
      // 昇順に並び替える
      // NOTE: パネル数 = Array(3)
      Array(3)
        .fill(1)
        .forEach((ele, i) => {
          this.config[`Panel${ele + i}`] = layout?.user_pages_items
            .filter(x => x.panel_id === ele + i)
            .filter(x =>
              this.feature.some(y => y.enable && x.feature == y.feature)
            ) // 有効な機能のみ表示する
            .sort((a, b) => a.row - b.row)
            .map(y => {
              return y.feature;
            });
        });
    }
  }
};
</script>
